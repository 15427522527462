<template>
  <div>
    <van-nav-bar title="经营品类" @click-left="onClickLeft">
      <template #left> <van-icon name="arrow-left" size="18" /> </template
    ></van-nav-bar>
    <van-field
      v-model="value"
      readonly
      clickable
      label="主营类目"
      placeholder="选择经营类目"
      @click="showPicker = true"
    />

    <van-popup v-model="showPicker" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="选择经营类目"
        :options="menulist"
        :field-names="fieldNames"
        @close="showPicker = false"
        @change="onChange"
        @finish="onFinish"
      />
    </van-popup>
  </div>
  
</template>

<script>
import { getObj } from "@/api/businessCategory";
export default {
  data() {
    return {
      showPicker: false,
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "id",
        children: "",
      },
      value: "",
      menulist: [{}],
    };
  },

  created() {
    this.getObj();
    this.$store.commit("setSession", this.$route.query.session);
    console.log(this.$route, 'route')
  },
  methods: {
    // 标题返回键
    onClickLeft() {},
    // 选择经营品类取消键
    onChange({ value }) {
      if (value === this.menulist[0].value) {
        setTimeout(() => {
          this.menulist[0].children = [
            { text: "", value: "" },
            { text: "", value: "" },
          ];
        }, 500);
      }
    },
    // 选择完后触发事件
    onFinish({ selectedOptions }) {
      this.showPicker = false;
      // console.log(selectedOptions);
      this.value = selectedOptions.map((menulist) => menulist.name).join("/");
    },

    getObj() {
      getObj().then((res) => {
        // console.log(res);
        res.data.data.forEach((item) => {
          // if(item.children===[]){
          //   return
          // }
          // item.text = item.name;
          item.children.forEach((item) => {
            // item.text = item.name;
            // item.children=''
            // if (item.children === false) {
            //   return
            // }
            if (item.children.length == 0) {
              delete item.children;
              // console.log(item.name)
            } else {
              item.children.forEach((item) => {
                //   // item.text = item.name;
                //   // item.children = "";
                item.children = "";
                // if(item.children==[]){
                //   return
                // }

                // item.children=null
                // console.log(item, '54')
              });
            }
          });
        });
        this.menulist = res.data.data;
        // console.log("menulist", this.menulist);
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>